<template>
  <div class="home">
    <CustomHeader></CustomHeader>
    <div class="banner-bg">
      <div class="slogan">
        <p class="p1">USE SCENARIOS</p>
        <p class="p2">智慧电力解决方案</p>
      </div>
      <div class="banner-title opcity">
        <div class="core">
          <div @click="view('#comprehensive')">无人机综合应用</div>
          <div @click="view('#transport')">输电巡检</div>
          <div @click="view('#match')">配电巡检</div>
          <div @click="view('#transformer')">变电站巡检</div>
        </div>
      </div>
    </div>
    <!-- 用途场景 -->
    <div class="bg-c">
      <div class="pt-8">
        <h1 class="h1" data-aos="fade-down">用途场景</h1>
        <Title back="#eff5ff" title="Application Scenario"></Title>
      </div>
      <!-- 视频 -->
      <div class="video">
        <video v-if="viewFlag" class="opcity" ref="videoPlayer" webkit-playsinline playsinline x5-playsinline muted autoplay loop src="https://cdn.aihangtec.com/index/video/electric.mp4"></video>
        <img class="unopcity" src="https://cdn.aihangtec.com/index/video/electric.gif" />
      </div>
    </div>

    <!-- 用途场景卡片 -->
    <div class="bg-w p-6" id="comprehensive">
      <div class="core card1">
        <div class="card-img">
          <img class="opcity" src="https://cdn.aihangtec.com/index/image/plan/c1.png" />
          <img class="unopcity" src="https://cdn.aihangtec.com/index/image/plan/mc1.png" />
        </div>
        <div class="card-inner">
          <div class="card-title">
            <div class="card-num" data-aos="flip-left">01</div>
            <div class="line"></div>
            <div>无人机综合应用</div>
          </div>
          <div class="card-text">无人机综合应用平台是集设备、航线、任务、作业以及空运审批为管理单元，基于无人机自动驾驶技术可实现电力行业杆塔、线路、数据采集、数据处理、缺陷识别为一体的综合管控平台。</div>
          <div class="card-type" data-aos="fade-right">
            <div>· 三维全景建模</div>
            <div>· 异地起降</div>
            <div>· 多机协同</div>
            <div>· 脱控作业</div>
            <div>· 实时监控</div>
            <div>· 远程操控</div>
            <div>· 应急指挥</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-f p-6" id="transport">
      <div class="core card1">
        <div class="card-inner">
          <div class="right">
            <div class="card-title">
              <div class="card-num" data-aos="flip-left">02</div>
              <div class="line"></div>
              <div>输电巡检</div>
            </div>
            <div class="card-text">
              无人机通过点云规划等生成输电线路三维模型，遵循精细化数据采集标注生成自动规划巡检航线，经过平台一键远程调度，完成无人机输电线路巡检，并通过数据采集、数据分析在巡检作业完毕后生成巡检数据报告。
            </div>
            <div class="card-type two" data-aos="fade-left">
              <div>· 点云规划</div>
              <div>· 三维建模</div>
              <div>· 多库跳飞</div>
              <div>· 移动降落</div>
              <div>· 实时监控</div>
              <div>· 远程监管</div>
              <div>· 应急指挥</div>
            </div>
          </div>
        </div>
        <div class="card-img">
          <img class="opcity" src="https://cdn.aihangtec.com/index/image/plan/c2.png" />
          <img class="unopcity" src="https://cdn.aihangtec.com/index/image/plan/mc2.png" />
        </div>
      </div>
    </div>
    <div class="bg-w p-6" id="match">
      <div class="core card1">
        <div class="card-img">
          <img class="opcity" src="https://cdn.aihangtec.com/index/image/plan/c3.png" />
          <img class="unopcity" src="https://cdn.aihangtec.com/index/image/plan/mc3.png" />
        </div>
        <div class="card-inner">
          <div class="card-title">
            <div class="card-num" data-aos="flip-left">03</div>
            <div class="line"></div>
            <div>配电巡检</div>
          </div>
          <div class="card-text">
            突破航线限制，使用以杆塔为单位的路径规划模式，实现配电巡检的任务高度自由性，覆盖配网线路精细化巡检、通道巡检、特殊巡检等巡检方式，通过脱控精飞、异地起降等功能完成集群管控，网格化部署，并配合平台强大负载能力，实现多机协同作业。
          </div>
          <div class="card-type" data-aos="fade-right">
            <div>· 自主飞巡</div>
            <div>· 脱控精飞</div>
            <div>· 异地起降</div>
            <div>· 缺陷识别</div>
            <div>· 实时监控</div>
            <div>· 远程监管</div>
            <div>· 应急指挥</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-f p-6" id="transformer">
      <div class="core card1">
        <div class="card-inner">
          <div class="right">
            <div class="card-title">
              <div class="card-num" data-aos="flip-left">04</div>
              <div class="line"></div>
              <div>变电站巡检</div>
            </div>
            <div class="card-text">
              通过研究室内无源环境的地图构建和自主定位功能研发，完善室内空间路径探索规划的飞行导航功能，利用室内建图、激光帧匹配、状态估计和SLAM整合等对无人机室内飞行巡检提供安全保障；同时基于AI的仪器仪表数据分析功能，使无人机在复杂多样的室内环境依旧可以实现对仪器仪表的拍摄识别，并结合RFID技术实现防爆门的智能开关从而完成室内无障碍飞行。
            </div>
            <div class="card-type four" style="bottom: -5px" data-aos="fade-left">
              <div class="opcity">· 无人值守自动作业</div>
              <div class="opcity">· 室内无障碍飞行</div>
              <div>· 数据智能分析</div>
              <div>· 表计读取</div>
              <div class="opcity">· 仪器仪表定位拍摄</div>
              <div>· 开关分合状态</div>
              <div>· 噪声收集</div>
            </div>
          </div>
        </div>
        <div class="card-img">
          <img class="opcity" src="https://cdn.aihangtec.com/index/image/plan/c4.png" />
          <img class="unopcity" src="https://cdn.aihangtec.com/index/image/plan/mc4.png" />
        </div>
      </div>
    </div>
    <!-- 功能优势 -->
    <div>
      <div class="pt-3">
        <h1 class="h1" data-aos="fade-up">功能优势</h1>
        <Title title="Platform characteristics"></Title>
        <div class="function core">
          <div>
            <img src="https://cdn.aihangtec.com/index/image/plan/e1.png" />
            <div class="f-text">
              <div data-aos="fade-up">
                <p class="title">单库覆盖作业模式</p>
                <p>单台无人机以全自动机巢为中心，以6km为作业半径， 完成覆盖范围内所有电力设备的自主有效巡检</p>
              </div>
              <div data-aos="fade-up" data-aos-delay="100">
                <p class="title">多库跳飞作业模式</p>
                <p>单台无人机可从一台全自动机巢或虚拟机巢起飞,飞至下一台全自动机巢或虚拟机巢进行“补给”，完成长距离输电线路的无人化自主巡检</p>
              </div>
              <div data-aos="fade-up" data-aos-delay="100">
                <p class="title">多机协同作业模式</p>
                <p>多台无人机可从不同全自动机巢出发，完成多机同向/异向飞行， 有效提升线路巡检效率</p>
              </div>
            </div>
          </div>
          <div class="f-t">
            <div class="mb-1">
              <img src="https://cdn.aihangtec.com/index/image/plan/e2.png" />
              <div class="f-text">
                <div data-aos="fade-up">
                  <p class="title">塔体精细化巡检</p>
                  <p>针对杆塔进行塔体、绝缘子、大/小尺寸金具及附属设施等杆塔细节展开检测</p>
                </div>
              </div>
            </div>
            <div>
              <img src="https://cdn.aihangtec.com/index/image/plan/e3.png" />
              <div class="f-text">
                <div data-aos="fade-up">
                  <p class="title">线路通道巡检</p>
                  <p>针对线路周边物体外破威胁评估，对线路通道大尺寸异物进行检测</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 平台特点 -->
    <div>
      <div class="main pt-8">
        <h1 class="h1" data-aos="fade-up">平台特点</h1>
        <Title title="Platform Features"></Title>
        <div class="core list-wrap mt-2">
          <div class="list">
            <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/p1.png" /></div>
            <div>
              <p class="title">电缆线巡视</p>
            </div>
          </div>
          <div class="list">
            <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/p2.png" /></div>
            <div>
              <p class="title">树障实时研判</p>
            </div>
          </div>
          <div class="list">
            <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/p3.png" /></div>
            <div>
              <p class="title">前端目标识别</p>
            </div>
          </div>
          <div class="list">
            <div class="img-wrap"><img src="https://cdn.aihangtec.com/index/image/plan/p4.png" /></div>
            <div>
              <p class="title">后端缺陷识别</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 客户案例 -->
    <div class="pt-8 mb-8">
      <h1 class="h1" data-aos="fade-up">客户认可</h1>
      <Title title="Customer Recognition"></Title>
      <CustomSwiper />
    </div>

    <Fixed class="opcity"></Fixed>
    <CustomFooter></CustomFooter>
  </div>
</template>

<script>
import AOS from "aos"
import { scrollInto } from "../../utils/util"
export default {
  data() {
    return {
      viewFlag: true
    }
  },
  mounted() {
    AOS.init()
    if (document.body.clientWidth < 780) {
      this.viewFlag = false
    } else {
      scrollInto()
    }
  },
  methods: {
    view(link) {
      scrollInto(link)
    },
    custom() {
      this.$router.push("/custom")
      document.documentElement.scrollTop = 0
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../style/plan.scss";
.card-type {
  & > div {
    @media screen and (max-width: 540px) {
      width: 32% !important;
      margin-top: 10px;
    }
    @media screen and (min-width: 540px) {
      width: 25% !important;
      margin-top: 24px;
    }
  }
}
</style>
